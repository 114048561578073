<template>
  <div class="totalBody">
    <div class="headerBox">
      <p>在库时效看板</p>
      <div class="currentDate">{{ nowTime }}</div>
    </div>

    <el-row class="rows" :gutter="40">
      <el-col :span="12">
        <div class="box reportBox">
          <h4 class="chart-title">IQC在库时效</h4>
          <div class="tableBox">
            <el-table :data="iqcList">
              <el-table-column align="center" prop="stationName" label="" width="200"></el-table-column>
              <el-table-column v-for="col in iqcCols" :key="col.id" :prop="col.id" :label="col.name" align="center"></el-table-column>
            </el-table>
          </div>
        </div>

      </el-col>
      <el-col :span="12">
        <div class="box reportBox">
          <h4 class="chart-title">RMC在库时效</h4>
          <div class="tableBox">
            <el-table :data="rmcList">
              <el-table-column align="center" prop="stationName" label="" width="200"></el-table-column>
              <el-table-column v-for="col in rmcCols" :key="col.id" :prop="col.id" :label="col.name" align="center"></el-table-column>
            </el-table>
          </div>
        </div>

      </el-col>
    </el-row>

    <el-row class="rows" :gutter="40">
      <el-col :span="12">
        <div class="box chart-box">
          <h4 class="chart-title">IQC时效达成率</h4>
          <el-row>
            <el-col :span="12">
              <div id="iqcChat" class="iqc-chat"></div>
            </el-col>
            <el-col :span="12">
              <div id="iqcChatMonth" class="iqc-chat"></div>
            </el-col>
          </el-row>
        </div>

      </el-col>
      <el-col :span="12">
        <div class="box chart-box">
          <h4 class="chart-title">RMC时效达成率</h4>
          <el-row>
            <el-col :span="12">
              <div id="rmcChat" class="rmc-chat"></div>
            </el-col>
            <el-col :span="12">
              <div id="rmcChatMonth" class="rmc-chat"></div>
            </el-col>
          </el-row>
        </div>

      </el-col>
    </el-row>

  </div>
</template>

<script>
import {currentDate} from "@/utils/common";
import * as echarts from "echarts";

export default {
  name: "PackMaterialBoard",
  data() {
    return {
      nowTime: currentDate(),
      iqcCols: [{id: 'h24', name: '24H'}, {id: 'h48', name: '48H'}, {id: 'h72', name: '72H'}, {id: 'more', name: '72H以上'}],
      rmcCols: [{id: 'h24', name: '24H'}, {id: 'h36', name: '36H'}, {id: 'h48', name: '48H'}, {id: 'more', name: '48H以上'}],
      iqcList: [],
      rmcList: [],
      iqcChart: '',
      iqcChartMonth: '',
      rmcChart: '',
      rmcChartMonth: ''
    }
  },
  mounted() {
    document.title = '看板'

    setInterval(() => this.nowTime = currentDate(), 1000);

    this.query()

    setInterval(() => {
      this.query()
    }, 1000 * 30)

  },
  methods: {
    query() {
      this.$axios.get('report/queryInStoreAgingHourCnt').then(response => {
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }
        let obj = res.data || {}
        this.iqcList = obj.iqcList
        this.rmcList = obj.rmcList

      })

      this.$axios.get('report/queryReachPieData').then(response => {
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }
        let obj = res.data || {}

        if (!this.iqcChart) {
          this.drawChart();
        }

        //obj.iqcReach = {h24: 10, h48: 10, h72: 10, more: 20}
        //obj.rmcReach = {h24: 10, h48: 30, h36: 10, more: 20}

        this.iqcChart.setOption({
          title: {text: '当日达成率'},
          series: [{data: this.getData(obj.iqcReach, this.iqcCols)}]
        })

        this.iqcChartMonth.setOption({
          title: {text: '当月达成率'},
          series: [{data: this.getData(obj.iqcReachMonth, this.iqcCols)}]
        })

        this.rmcChart.setOption({
          title: {text: '当日达成率'},
          series: [{data: this.getData(obj.rmcReach, this.rmcCols)}]
        })
        this.rmcChartMonth.setOption({
          title: {text: '当月达成率'},
          series: [{data: this.getData(obj.rmcReachMonth, this.rmcCols)}]
        })


      })
    },
    getData(reach, cols) {
      if (!reach) {
        return []
      }
      let data = []
      let total = 0;
      for (let col of cols) {
        let value = reach[col.id] || 0;
        total += value;
        data.push({value, name: col.name})
      }

      if (total == 0) {
        data = []
      }
      return data
    },
    createOption(data) {
      let option = {
        title: {
          left: '170px',
          textStyle: {
            color: '#fff'
          }

        },
        textStyle: {
          color: '#fff',
          fontSize: '14px',
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          bottom: '4px',
          textStyle: {
            color: '#fff',
          },
        },
        series: [
          {
            type: 'pie',
            radius: ['20%', '50%'],
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 4,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: true,
              position: 'outside',
              color: "#fff",
              formatter: '{b}\n({d}%)'
            },
            labelLine: {
              show: true
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 24,
                fontWeight: 'bold'
              }
            },
            data: data
          }
        ]
      };
      return option;
    },
    drawChart() {
      this.iqcChart = echarts.init(document.getElementById('iqcChat'))
      this.iqcChart.setOption(this.createOption());

      this.iqcChartMonth = echarts.init(document.getElementById('iqcChatMonth'))
      this.iqcChartMonth.setOption(this.createOption());

      this.rmcChart = echarts.init(document.getElementById('rmcChat'))
      this.rmcChart.setOption(this.createOption());

      this.rmcChartMonth = echarts.init(document.getElementById('rmcChatMonth'))
      this.rmcChartMonth.setOption(this.createOption());
    }
  }
}
</script>

<style scoped>
.totalBody {
  color: #ffffff;
  min-width: 1900px;
  min-height: 1080px;
  background: url('../../assets/images/report/bg_report.png') no-repeat;
  background-size: cover; /* 拉伸图片覆盖整个区域 */
  padding: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.headerBox {
  width: 100%;
  height: 89px;
  background: url('../../assets/images/report/header.png') no-repeat;
  background-size: 100% 100%;
  position: relative;
  margin-bottom: 10px;
  margin-top: 5px;
}

p {
  margin: 0 0 10px;
  padding-top: 5px;
}

.headerBox > p {
  text-align: center;
  font-size: 36px;
  font-weight: bold;
}

.headerBox > div {
  position: absolute;
  top: 0;
}

.lineName {
  font-size: 18px;
  right: 200px;
  line-height: 35px;
}

.currentDate {
  font-size: 14px;
  right: 30px;
  line-height: 35px;
}

.rows {
  padding-left: 60px;
  padding-right: 60px;
  margin-bottom: 20px;
}

.chart-title {
  font-weight: normal;
  font-size: 24px;
  margin-top: 0px;
  margin-bottom: 10px;
  text-align: center;
  color: #01c4f7;
}

.chart-title:before {
  content: url('../../assets/images/report/title_before.png');
}

.chart-title:after {
  content: url('../../assets/images/report/title_after.png');
}

.box {
  background: url('../../assets/images/report/report1_bg.png') no-repeat;
  background-size: 100% 100%;
}

.reportBox {
  height: 600px;
}

.iqc-chat, .rmc-chat {
  height: 290px;
}

.tableBox /deep/ .el-table tr {
  background-color: transparent;
  border: none;
}

.tableBox /deep/ .el-table th, .el-table tr, .el-table td {
  background-color: transparent;
  border-style: none;
}

.tableBox /deep/ table tbody tr:nth-child(odd) {
  background: rgba(30, 176, 252, .3);
  border: none;
}

.tableBox /deep/ .el-table, .el-table__expanded-cell {
  background-color: transparent;
  border: none;
}

.tableBox /deep/ .el-table--enable-row-transition .el-table__body td, .el-table .cell {
  background-color: transparent;
  border: none;
  padding-top: 7px;
  padding-bottom: 7px;
}

.tableBox /deep/ .el-table td, thead {
  color: #1eb0fc;
  font-size: 18px;
}

.tableBox /deep/ .el-table th, thead {
  color: #1eb0fc;
  font-size: 18px;
  padding: 5px 0;
}

.el-table--border::after, .el-table--group::after, .el-table::before {
  display: none;
}

</style>